import React from "react";
import styled from "styled-components";
import {
  Table,
  SearchInput,
  Filters,
  FilterGroup,
} from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { API_URL } from "../config";
import NewLocation from "../components/location/NewLocations";
import { Modal } from "@mui/material";
import SwitchFilter from "../components/tables/table/filters/SwitchFilter";
import CheckFilter from "../components/tables/table/filters/CheckFilter";
import DateFilter from "../components/tables/table/filters/DateFilter";

/* Styled components */

const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  padding: 0;
  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.color ? props.color : "#000")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px 10px;
  @media (min-width: 1024px) {
    padding: 20px;
  }
`;

export default function LocationsReportPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();

  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    tableRef.current.clean();
    setOpen(false);
  };
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    tableRef.current.clean();
    setOpenEdit(false);
  };

  const [editLocation, setEditLocation] = React.useState(null);

  const [search, setSearch] = React.useState("");

  const filters = [
    {
      name: "horse",
      label: intl.formatMessage({ id: "horse" }),
      type: "check",
      src: `${API_URL}/horses/list`,
    },
    {
      name: "location",
      label: intl.formatMessage({ id: "location" }),
      type: "check",
      src: `${API_URL}/locations`,
    },
    {
      name: "personCharge",
      label: intl.formatMessage({ id: "personCharge" }),
      type: "check",
      src: `${API_URL}/human/all`,
    },
    {
      start: "date",
      end: "departureDate",
      label: intl.formatMessage({ id: "period" }),
      type: "doubleDate",
    },
  ];

  const columnsTable = [
    {
      title: translate("horse"),
      field: "horse",
      width: "20%",
      order: true,
      hidden: false,
    },
    {
      title: translate("location"),
      field: "location",
      width: "20%",
      order: true,
      hidden: false,
    },
    {
      title: translate("responsible"),
      field: "responsible",
      width: "20%",
      order: true,
      hidden: false,
    },
    {
      title: translate("arriveDate"),
      type: "date",
      field: "date",
      width: "20%",
      order: true,
      hidden: false,
    },
    {
      title: translate("departureDate"),
      type: "date",
      field: "departureDate",
      width: "20%",
      order: true,
      hidden: false,
    },
    {
      title: translate("link"),
      actions: true,
      width: "auto",
      align: "center",
      hidden: true,
    },
  ];

  const handleEditItem = (data) => {
    setEditLocation(data);
    handleOpenEdit();
  };

  const onApplyFilter = () => {
    tableRef.current.clean();
  };

  const onClearFilter = () => {
    setSelectedFilters(defaultSelectedFilters);
  };

  const defaultSelectedFilters = [
    {
      name: "horse",
      values: [],
      condition: "equal",
      type: "object",
      search: "",
    },

    {
      name: "location",
      values: [],
      condition: "equal",
      type: "object",
    },
    {
      name: "personCharge",
      values: [],
      condition: "equal",
      type: "object",
      search: "",
    },
    {
      name: "date",
      values: [],
      condition: ">=",
      type: "date",
    },
    {
      name: "departureDate",
      values: [],
      condition: "<=",
      type: "date",
    },
  ];

  const [selectedFilters, setSelectedFilters] = React.useState(
    defaultSelectedFilters
  );

  const handleFilterSearch = (name, value) => {
    const oldFilters = selectedFilters;
    const newFilters = selectedFilters.filter((f) => f.name !== name);
    const filterChange = oldFilters.find((f) => f.name === name);
    filterChange["search"] = value;
    newFilters.push(filterChange);
    setSelectedFilters(newFilters);
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const handleSearch = () => {
    tableRef.current.clean();
  };

  const handleDeleteItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL}/locations/${data.id}`)
        .then((result) => {
          tableRef.current.clean();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLink = (data) => {
    window.open(data.url, "_blank");
  };

  const handleCheckFilter = (name, value) => {
    const olds = selectedFilters;
    const news = selectedFilters.filter((f) => f.name !== name);
    const filterChange = olds.find((f) => f.name === name);
    if (filterChange.values.includes(parseInt(value))) {
      const newArray = filterChange.values.filter(
        (val) => val !== parseInt(value)
      );
      filterChange["values"] = newArray;
      news.push(filterChange);
      setSelectedFilters(news);
    } else {
      const uptArray = filterChange.values;
      uptArray.push(parseInt(value));
      filterChange["values"] = uptArray;
      news.push(filterChange);
      setSelectedFilters(news);
    }
  };

  const handleChangeSwitch = (name, value) => {
    const oldFilters = selectedFilters;
    const newFilters = selectedFilters.filter((f) => f.name !== name);
    const filterChange = oldFilters.find((f) => f.name === name);
    filterChange["values"] = [value];
    newFilters.push(filterChange);
    setSelectedFilters(newFilters);
  };

  const handleDateChange = (name, value) => {
    const oldFilters = selectedFilters;
    const newFilters = selectedFilters.filter((f) => f.name !== name);
    const filterChange = oldFilters.find((f) => f.name === name);
    filterChange["values"] = value;
    newFilters.push(filterChange);
    setSelectedFilters(newFilters);
  };

  const actionsTable = {
    link: {
      icon: theme.icons.link,
      tooltip: "Link",
      onClick: handleLink,
      color: theme.palette.principal,
      center: true,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
  };

  const options = {
    exportButton: true,
    exportName: intl.formatMessage({ id: "locations" }),
    directExport: true,
    pagination: true,
  };

  return (
    <Root>
      <Content>
        <Table
          disableTotalTitle={true}
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable="list"
          options={options}
          data={(query) =>
            new Promise((resolve, reject) => {
              let filterList = selectedFilters.filter(
                (fil) => fil.values.length > 0
              );
              if (filterList.length === 0) {
                filterList = null;
              } else {
                filterList = JSON.stringify(filterList);
              }
              axios
                .get(`${API_URL}/report/horsesxlocations`, {
                  params: {
                    page: query.page - 1,
                    limit: query.pageSize,
                    search: search,
                    order: query.order,
                    filter: filterList,
                  },
                })
                .then((education) => {
                  resolve({
                    rows: education.data.rows,
                    total: education.data ? education.data.total : 0,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          dataExport={(query) =>
            new Promise((resolve, reject) => {
              let filterList = selectedFilters.filter(
                (fil) => fil.values.length > 0
              );
              if (filterList.length === 0) {
                filterList = null;
              } else {
                filterList = JSON.stringify(filterList);
              }
              axios
                .get(`${API_URL}/export/horsesxlocations`, {
                  params: {
                    search: search,
                    order: query.order,
                    filter: filterList,
                  },
                  responseType: "blob",
                })
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", query.exportName + ".xlsx"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        >
          <SearchInput
            search={search}
            onChange={onSearchChange}
            onSearch={handleSearch}
          />
          <Filters clear={onClearFilter} action={onApplyFilter}>
            {filters &&
              filters.map((fil, index) => (
                <FilterGroup
                  onChange={handleFilterSearch}
                  name={fil.name && fil.name}
                  search={
                    fil.name &&
                    selectedFilters.find((ft) => ft.name === fil.name).search &&
                    selectedFilters.find((ft) => ft.name === fil.name).search
                  }
                  key={`filter_group_${index}`}
                  title={fil.type === "switch" ? null : fil.label}
                >
                  {fil.type === "switch" ? (
                    <SwitchFilter
                      filter={fil}
                      value={
                        selectedFilters.find((ft) => ft.name === fil.name)
                          .values[0]
                      }
                      onChange={handleChangeSwitch}
                    />
                  ) : fil.type === "doubleDate" ? (
                    <DateFilter
                      filter={fil}
                      values={
                        selectedFilters.find((ft) => ft.name === fil.start)
                          .values
                      }
                      onChange={handleDateChange}
                    />
                  ) : (
                    <CheckFilter
                      filter={fil}
                      values={
                        selectedFilters.find((ft) => ft.name === fil.name)
                          .values
                      }
                      search={
                        selectedFilters.find((ft) => ft.name === fil.name)
                          .search &&
                        selectedFilters.find((ft) => ft.name === fil.name)
                          .search
                      }
                      handleChange={handleCheckFilter}
                    />
                  )}
                </FilterGroup>
              ))}
          </Filters>
        </Table>
        <Modal open={open} onClose={handleClose}>
          <NewLocation handleClose={handleClose} />
        </Modal>
        <Modal open={openEdit} onClose={handleCloseEdit}>
          <NewLocation
            locationData={editLocation}
            handleClose={handleCloseEdit}
          />
        </Modal>
      </Content>
    </Root>
  );
}
